import { useState, useEffect } from 'react';
import FormControlLabel from "@mui/material/FormControlLabel";
import { IconButton, InputAdornment, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useNavigate } from 'react-router-dom'
import SnackbarComponent from '../components/toastmessage'
import axios from 'axios'
import CircularProgress from '@mui/material/CircularProgress';
import GlobalVariable from '../path/global'

const baseApiUrl = GlobalVariable.BASE_API_URL

const LoginForm = () => {
  const navigate = useNavigate();
 
  // Login form state
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [rememberMe, setRememberMe] = useState(false);

  // Snackbar state
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  
  // Email and password regular expressions
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  // const passwordRegex =/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&^#&_-])[A-Za-z\d@$!%*?&^#&_-]{6,}$/;

  useEffect(() => {
    const savedEmail = localStorage.getItem('rememberedEmail');
    const savedPassword = localStorage.getItem('rememberedPassword');
    if (savedEmail && savedPassword) {
      setEmail(savedEmail);
      setPassword(savedPassword);
      setRememberMe(true);
    }
  }, []);

  const onSubmit = (event) => {
    event.preventDefault();
   
    setEmailError('');
    setPasswordError('');

    if (rememberMe) {
      localStorage.setItem('rememberedEmail', email);
      localStorage.setItem('rememberedPassword', password);
    } else {
      localStorage.removeItem('rememberedEmail');
      localStorage.removeItem('rememberedPassword');
    }

    if (!email) {
      setEmailError("Please Enter Email");
      setIsLoading(false);
      
    }
  
    if (!password) {
      setPasswordError("Please Enter Password");
      setIsLoading(false);
      return;
    } 
  
    // if (!passwordRegex.test(password)) {
    //   setPasswordError("Please enter a valid password");
    //   setIsLoading(false);
      
    // }

    if (!emailRegex.test(email)) {
      setEmailError("Please enter a valid email address.");
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    setIsSubmitDisabled(true);
   
    axios.post(`${baseApiUrl}users/login`, {
      email,
      password
    })
    .then(response => {
        if (response.data && response.data?.user) {
          const { user,access_token } = response.data;
        
          const userData = {
            user,
            access_token
          };
          
          localStorage.setItem('userdetails', JSON.stringify(userData));
          navigateAfterRender();
          setIsLoading(false);
          setSnackbarMessage('Login successful');
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
        } else {
          console.error('Invalid response structure:', response.data);
        }
    })
    .catch(error => {
        console.error("Error:", error);
        if (error.response && error.response.request.status === 401 ) {
          setSnackbarMessage(error.response.data.error);
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        } else if (error.response && error.response.request.status=== 422) {
          setSnackbarMessage(error.response.data.error);
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
            // Handle other specific status codes as needed
        } else if (error.response && error.response.request.status === 404) {
          setSnackbarMessage(error.response.data.error);
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        } else {
          // Handle other errors
        }
    })
    .finally(() => {
        setIsSubmitDisabled(false);
        setIsLoading(false);
    });
  
  }

  const navigateAfterRender = () => {
    const localData = JSON.parse(localStorage.getItem('userdetails'));
    // if( (localData.user.roleId === '1') || (localData.user.roleId === '4')) {
    //   navigate('/users');
    //   console.log("true 1")
    // } else {
      navigate('/profile-matching');
      setIsLoading(true);

      // navigate(0);
    // }
  };

  const handleRememberMe = () => {
    setRememberMe(!rememberMe);
  };

  const handleEmailChange = (e) => {
    const { value } = e.target;
    setEmail(value);
    setEmailError(false);
  };

  const handlePasswordChange = (e) => {
    const { value } = e.target;
    setPassword(value);
    setPasswordError(false);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  }

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  // Function to handle email blur event
  const handleEmailBlur = () => {
    if (!emailRegex.test(email) && email.trim() !== '') {
      setEmailError("Please enter a valid email address.");
    } else {
      setEmailError('');
    }
  };

  // Function to handle password blur event
  // const handlePasswordBlur = () => {
  //   if (!passwordRegex.test(password) && password.trim() !== '') {
  //     setPasswordError("Please enter a valid password");
  //   } else {
  //     setPasswordError('');
  //   }
  // };

  return (
    <div className='loginForm-container'>
       {isLoading && (
        <div className="full-page-loader">
          <CircularProgress size={100} />
        </div>
      )}
      <Container component="main" maxWidth="sm" justifyContent="flex-end">
        <Card sx={{ minWidth: 275, marginTop: 8, borderRadius: 5 }}>
          <CardContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box display={'flex'} width={'100%'} className="p-5px">
                <BusinessCenterIcon color='primary' sx={{ fontSize: 30 }} style={{ marginRight: '6px' }} />
                <Typography color='primary' paddingRight={1} sx={{ fontSize: 25 }} style={{ fontWeight: '700' }} >
                  Hiring
                </Typography>
                <Typography sx={{ fontSize: 25 }} style={{ fontWeight: '700' }}>
                  Insights
                </Typography>
              </Box>
              <Box className="login-form" width={'100%'}>
               
                  <Box display={'flex'} alignItems={'center '} flexDirection={'column'}>
                  <Typography mt={2} sx={{ fontSize: 32 }} style={{ fontWeight: '700' }} >
                    Login to Account
                  </Typography>
                  <Typography mt={2} sx={{ fontSize: 18 }} >
                    Please enter your email and password to continue
                  </Typography>
                  </Box>
                
                <Box component="form" onSubmit={onSubmit} noValidate sx={{ mt: 1 }} paddingBottom={5}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    sm={12}
                    id="email"
                    label="Email Address"
                    name="email"
                    value={email}
                    onChange={handleEmailChange}
                    onBlur={handleEmailBlur} // Add onBlur event handler
                    autoComplete="email"
                    autoFocus
                    error={!!emailError} 
                    helperText={emailError} 
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    type={showPassword ? "text" : "password"}
                    name="password"
                    label="Password"
                    value={password}
                    onChange={handlePasswordChange}
                    // onBlur={handlePasswordBlur} // Add onBlur event handler
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    id="password"
                    autoComplete="current-password"
                    error={!!passwordError} 
                    helperText={passwordError}
                  />

                  <Grid container alignItems={'center'} justifyContent={'space-between'} display={'inline-flex'}>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={<Checkbox checked={rememberMe} onChange={handleRememberMe} color="primary" />}
                        label="Remember me"
                      />
                    </Grid>
                  </Grid>
                  <Box display={'flex'} justifyContent={'center'}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2, width: 400, borderRadius: 2 }}
                      disabled={isSubmitDisabled}
                      className='btn btn-submit'
                      size="large"
                    >
                      Sign In
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Container>
      <SnackbarComponent
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </div>
  );
};

export default LoginForm;
