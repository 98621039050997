import React from 'react';
import {  Navigate } from 'react-router-dom';


function PrivateRoute ({children}){
  const getUserDetails = JSON.parse(localStorage.getItem('userdetails'));
  const isAuthenticated = getUserDetails && getUserDetails.access_token;

return isAuthenticated ? <>{children}</>:<Navigate to = '/login' />

}


export default PrivateRoute;
