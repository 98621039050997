import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const ThemeLoadingOverlay = () => {
  return (
    <div>
     <CircularProgress />
    
    </div>
  );
};

export default ThemeLoadingOverlay;