// ** User List Component
import { Fragment } from 'react'

import JobDescription from './jobdescription'
import JobAnalysisBreadcrumbs from '../../components/Breadcrumbs/index'


const ProfileMatchList = () => {
    return (
        <Fragment>
            <JobAnalysisBreadcrumbs title='Jobs' data={[{ title: 'Dashboard' }]}/>

            <div className='app-user-list'>
                <JobDescription />
            </div>
        </Fragment>
    )
}

export default ProfileMatchList