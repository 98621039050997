import React, { useState, useEffect, Fragment } from "react";
import { Routes, Route, useNavigate ,useLocation} from 'react-router-dom';
import AppRoutes from '../routes/approutes/AppRoutes';
// import NotFound from '../../pages/notfound/NotFound';
import NotFound from '../pages/notfound/NotFound';
import Loader from '../components/Loader/circularloader';
// import CircularProgress from '@mui/material/CircularProgress';

//files import
import UpdatedNavbar from '../components/UpdatedNavbar/updatednavbar';
// import SideBar from '../components/sidebar/SideBar';
// import AppRoutes from '../routes/approutes/AppRoutes';

import './mainLayout.css';

const MainLayout = ({ onLogout }) => {
  const [urlName, setUrlName] = useState('/')
  const [isLoading, setIsLoading] = useState(true);

  const location = useLocation();
 
  useEffect(() => {
    let n = location.pathname
    const pathSegments = n.split('/');
    if (pathSegments.length >= 3) {
    let  desiredPath = `/${pathSegments[1]}/${pathSegments[2]}`;
    setUrlName(desiredPath);
    }else{
      setUrlName(location.pathname);
    }
   
    
    setIsLoading(false);
  }, [location]);


  return (
    <div className='main_layout'>
      <div className='main_container' style={{position: 'relative'}}>
        
     
          
      {isLoading ? (
        <div className="" style={{position: 'absolute', background: 'rgba(255,255,255,0.5)', top:'50%', left:'50%',zIndex:1090}}>
        <div style={{position: 'absolute', background: 'rgba(255,255,255,0.5)', width:'100%',height:'100vh',display:"flex",justifyContent:'center',alignItems:'center',alignContent:'center',flexWrap:'wrap'}}>
           <Loader />
           </div>
          </div>
          
           
        )  : (
          <>

        {urlName === '/users' || urlName === '/profile-matching' || urlName === `/users/edit-user` || urlName === '/users/add-user' || urlName === '/job-description' || urlName === '/prompts' || urlName === '/role-prompts' || urlName === '/analysis-report' ? 
        <UpdatedNavbar />
        :
        <Routes>
     <Route path="*" element={<NotFound />} />
      </Routes>
        }
         </>
        )}
      </div>
    </div>
  );
};

export default MainLayout;
