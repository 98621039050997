import React,{lazy} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import reportWebVitals from './reportWebVitals';
import { StyledEngineProvider } from '@mui/material/styles';
import { BrowserRouter } from 'react-router-dom';
import App from './App'
const root = ReactDOM.createRoot(document.getElementById('root'));
function renderApp() {
  root.render(

      <StyledEngineProvider>
        <BrowserRouter>
            <App />
        </BrowserRouter>
      </StyledEngineProvider>

  );
}

// Call startTransition before rendering

  renderApp();


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
