import React, { useState } from 'react';
import { Typography, Upload, message } from 'antd';
import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid";
import {CloudUploadOutlined} from '@mui/icons-material';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import msword from '../../assets/images/msword.png'
import Tooltip from '@mui/material/Tooltip';
// import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';

// ... (your imports)

const FileUploads = ({ onFileChange, files }) => {
  const [setLoading] = useState(false);

  const beforeUpload = (file) => {
    const allowedFormats = [".doc", ".docx", ".pdf", "application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];

    const fileExtension = file.name.split('.').pop().toLowerCase();
  
    if (!allowedFormats.includes(fileExtension) && !allowedFormats.includes(file.type)) {
      message.error("You can only upload pdf and doc files");
      return false;
    }

    const isSizeValid = file.size / 1024 / 1024 < 2;
    if (!isSizeValid) {
      message.error("File must be smaller than 2MB!");
      return false;
    }

    onFileChange(file);
    setLoading(false);
    return false;
  };

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
    } else if (info.file.status === "done") {
      setLoading(false);
    }
  };

  const styles = {
    uploadText: {
      textAlign: 'center',
    },
    heading: {
      fontSize: '2rem',
      color: 'primary',
      marginBottom: '8px',
      fontWeight: 'bolder'
    },
    smallText: {
      '&.MuiTypography-root': {
        /* Your styles here */
      },
    },
  };

  return (
    <Box
      sx={{
        border: '1px dashed #ddd',
        padding: '8px',
        borderRadius: '4px',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <Upload
        beforeUpload={beforeUpload}
        onChange={handleChange}
        showUploadList={false}
      >
        {files ? (
          <Grid container spacing={2} justifyContent={'center'} alignContent={'center'} alignItems={'center'}>
            {files.type === 'application/msword' ? (
              <Grid item xs={6} sm={6} md={6} sx={styles.uploadText}>
                {/* <DescriptionOutlinedIcon style={{ fontSize: 32, color: 'green' }} /> */}
                <img src={msword} alt='msword' style={{ width: '30px',height:'30px', color: 'green' }} />

                <div
                  style={{
                    maxWidth: '60px',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    textAlign: 'center',
                  }}
                >
                  <Tooltip title={<div style={{ fontSize: '14px' }}> {files.name}</div>} arrow>
                    <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      {files.name}
                    </div>
                  </Tooltip>
                </div>
              </Grid>
            ) : files.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ? (
              <Grid item xs={6} sm={6} md={6} sx={styles.uploadText}>
                {/* Display an icon for DOC files */}
                <img src={msword}  alt='msword' style={{ width: '30px',height:'30px', color: 'blue' }} />
                {/* <DescriptionOutlinedIcon style={{ fontSize: 32, color: 'blue' }} /> */}
                <div
                  style={{
                    maxWidth: '60px',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    textAlign: 'center',
                  }}
                >
                  <Tooltip title={<div style={{ fontSize: '14px' }}> {files.name}</div>} arrow>
                    <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      {files.name}
                    </div>
                  </Tooltip>
                </div>
              </Grid>
            ) : (
              <Grid item xs={6} sm={6} md={6} sx={styles.uploadText}>
                <PictureAsPdfIcon className='pdf-icon' style={{ fontSize: 32, color: 'green !important' }} />
                <div
                  style={{
                    maxWidth: '60px',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    textAlign: 'center',
                  }}
                >
                  <Tooltip title={<div style={{ fontSize: '14px' }}> {files.name}</div>} arrow>
                    <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      {files.name}
                    </div>
                  </Tooltip>
                </div>
              </Grid>
            )}
            <Grid item xs={6} sm={6} md={6} className='upload-text'>
              <Typography variant="h3" color='primary'  sx={styles.heading} style={{fontWeight:'bold'}}>Select File here</Typography>
              <Typography size="small" sx={{ ...styles.smallText }}><span>Files Supported: </span> <span>PDF & Doc Only</span></Typography>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={2} justifyContent={'center'} alignContent={'center'} alignItems={'center'}>
            <Grid item xs={6} sm={6} md={6} sx={styles.uploadText}>
              <CloudUploadOutlined style={{ fontSize: 40, color: 'gray' }} />
            </Grid>
            <Grid item xs={6} sm={6} md={6} className='upload-text'>
              <Typography variant="h3" color='primary'  sx={styles.heading} style={{fontWeight:'bold'}}>Select File here</Typography>
              <Typography size="small" sx={{ ...styles.smallText }}><span>Files Supported: </span> <span>PDF & Doc Only</span></Typography>
            </Grid>
          </Grid>
        )}
      </Upload>
    </Box>
  );
};

export default FileUploads;
