// import { colors } from '@mui/material';
import React from 'react';
import Box from '@mui/material/Box';
// import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import CardContent from '@mui/material/CardContent';

const NotAuth = () => {
  return (
    <div className=''>
     
          <CardContent>
            <Box display={'flex'} width={'100%'} className="p-5px">
            <Typography color='#202224' paddingRight={1} sx={{ fontSize: 45 }} style={{ fontWeight: '900' }} >
                  401 - Not Authorization
                </Typography>
               
              </Box>
           </CardContent>

        
    </div>
  );
};

export default NotAuth;
