// ** User List Component
import { Fragment } from 'react'

import PreviousAnalysisReportPage from './PreviousReport'
import HistoryReportBreadcrumbs from '../../components/Breadcrumbs'


const ProfileAnalysisReport = () => {
    return (
        <Fragment>
            <HistoryReportBreadcrumbs title='Previous Analysis Report' data={[{ title: 'Dashboard' }]}/>

            <div className='app-previous-analysis-report'>
                <PreviousAnalysisReportPage />
            </div>
        </Fragment>
    )
}

export default ProfileAnalysisReport