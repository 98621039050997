// ** User List Component
import { Fragment } from 'react'

import ProfileMatchingPage from './ProfileMatchingPage'
import JobAnalysisBreadcrumbs from '../../components/Breadcrumbs'


const ProfileMatchList = () => {
    return (
        <Fragment>
            <JobAnalysisBreadcrumbs title='Profile Analysis' data={[{ title: 'Dashboard' }]}/>

            <div className='app-user-list'>
                <ProfileMatchingPage />
            </div>
        </Fragment>
    )
}

export default ProfileMatchList