// AddJobModal.jsx
import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/system';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import axios from 'axios';
import Backdrop from '@mui/material/Backdrop';
import GlobalVariable from '../../path/global';
import Loader from '../../components/Loader/circularloader';
import * as yup from 'yup';

const baseApiUrl = GlobalVariable.BASE_API_URL;

const StyledButton = styled(Button)(({ theme }) => ({
  fontSize: '18px',
  '&:hover': {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
  },
}));

const validationSchema = yup.object().shape({
  jobId: yup.string().required('Job ID is required'),
  jobName: yup.string().required('Job Name is required'),
  ClientName: yup.string().required('Client Name is required'),
  ClientIndustry: yup.string().required('Client Industry is required'),
  jobDescription: yup.string().required('Job Description is required'),
});

const AddJobModal = ({ open, onClose, jobName, initialData, reloadData, deleteOpen, onDeleteClose }) => {
  const [mode, setMode] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    jobName: '',
    jobDescription: '',
    ClientName:'',
    ClientIndustry:'',

  });
  const [fieldErrors, setFieldErrors] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  console.log("3444")

  useEffect(() => {
    console.log(initialData,"int")

    if (initialData) {
      // If initialData is provided, set form fields based on it
      setFormData({
        jobId: initialData.jobId || '',
        jobName: initialData.jobName?.split('-')[1] || '',
        jobDescription: initialData.jobDescription || '',
        ClientName: initialData.clientName || '',
        ClientIndustry: initialData.ClientIndustry || '',

      });
      setMode('edit');
    } else {
      setFormData({
        jobName: '',
        jobDescription: '',
        ClientName: '',
        ClientIndustry: '',

      });
      setMode('add');
    }
  }, [initialData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    // Clear the corresponding field error when the user starts typing again
    setFieldErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleSave = async () => {
    setIsLoading(true);

    try {
      // Validate the form data using Yup schema
      await validationSchema.validate(formData, { abortEarly: false });

      // Continue with the save logic if validation passes

      const existingData = JSON.parse(localStorage.getItem('userdetails')) || [];
      const companyId = existingData.user.companyId;

      if (mode === 'add') {
        const newData = { jobId: formData.jobId, jobName: formData.jobName, clientName:formData.ClientName, ClientIndustry:formData.ClientIndustry,  jobDescription: formData.jobDescription, companyId: companyId };

        const response = await axios.post(`${baseApiUrl}createJob`, newData);
        if (response.data.status === true) {
          setIsLoading(false);
          setSnackbarMessage('Job Data Successfully Added!!');
          setSnackbarSeverity('success');
          setOpenSnackbar(true);
          onClose();
          reloadData();

          // Reset form data after successful save
          setFormData({
            jobName: '',
            jobDescription: '',
            clientName: '',
            ClientIndustry: '',

          });
        } else if (response.data.status === false) {
          setIsLoading(false);
          setSnackbarMessage(response.data.message || 'Job ID already exists');
          setSnackbarSeverity('error');
          setOpenSnackbar(true);
        } else {
          setIsLoading(true);
          setSnackbarMessage('Error getting');
          setSnackbarSeverity('error');
          setOpenSnackbar(true);
          onClose();
        }
      } else {
        // Edit mode
        const response = await axios.put(`${baseApiUrl}updateJob/${initialData.id}`, {
          jobId: formData.jobId,
          jobName: formData.jobName,
          clientName: formData.ClientName,
          jobName: formData.jobName,
          ClientIndustry: formData.ClientIndustry,
        });

        if (response) {
          setOpenSnackbar(true);
          setIsLoading(false);
          onClose();
          setSnackbarMessage('Job Data Successfully Updated!!');
          setSnackbarSeverity('success');
          reloadData();

          // Reset form data after successful save
          setFormData({
            jobName: '',
            jobDescription: '',
            ClientName: '',
            ClientIndustry: '',
          });
        }
      }
    } catch (validationError) {
      // Handle validation errors
      const errorMessages = {};
      validationError.inner.forEach((error) => {
        errorMessages[error.path] = error.message;
      });
      setFieldErrors(errorMessages);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteJob = async () => {
    setIsLoading(true)
    const response = await axios.put(`${baseApiUrl}deActiveJob/${jobName}`);
    if (response) {
      onDeleteClose();
      setOpenSnackbar(true);
      setSnackbarMessage('Job Data Deleted Successfully !!');
      setSnackbarSeverity('success');
      setIsLoading(false);
      reloadData();
    } else {
      onDeleteClose();
      setIsLoading(true);
      setSnackbarMessage('Error from the server');
      setOpenSnackbar(true);
      setSnackbarSeverity('danger');
    }
  };

  const handleClose = () => {
    // Clear form data when the modal is closed
    setFormData({
      jobName: '',
      jobDescription: '',
      ClientName: '',
      ClientIndustry: '',
 });
    setFieldErrors({}); // Clear validation errors
    onClose();
  };

  return (
    <Box>
      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open} />
      <Dialog key={open} open={open} onClose={handleClose} maxWidth={'md'} fullWidth>
        <DialogTitle sx={{ backgroundColor: '#1976d2', color: 'white', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '8px', fontSize: '20px' }}>
          <span>{mode === 'add' ? 'Add Job' : 'Edit Job'}</span>
          {/* <IconButton onClick={onClose} style={{ color: 'white' }}>
            <CloseIcon />
          </IconButton> */}
        </DialogTitle>
        <DialogContent>
          <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center', background: 'rgba(255,255,255,0.5)', left: '50%', top: '50%' }}>
            {isLoading && <Loader />}
          </div>
          {mode !== 'delete' && (
            <>
              <div style={{ display: 'flex', justifyContent: 'space-around' }}>

                {mode == 'add' && 
                <TextField
                  label="Job Id"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  name="jobId"
                  value={formData.jobId}
                  onChange={handleChange}
                  style={{ paddingRight: '5px' }}
                  error={Boolean(fieldErrors.jobId)}
                  helperText={fieldErrors.jobId}
                />
          }

{mode == 'edit' && 
                <TextField
                  label="Job Id"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  disabled
                  name="jobId"
                  value={formData.jobId}
                  onChange={handleChange}
                  style={{ paddingRight: '5px' }}
                  error={Boolean(fieldErrors.jobId)}
                  helperText={fieldErrors.jobId}
                />
          }
                <TextField
                  label="Job Name"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  style={{ paddingLeft: '5px' }}
                  name="jobName"
                  value={formData.jobName}
                  onChange={handleChange}
                  error={Boolean(fieldErrors.jobName)}
                  helperText={fieldErrors.jobName}
                />
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-around' }}>

                {mode == 'add' && 
                <TextField
                  label="Client Name"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  name="ClientName"
                  value={formData.ClientName}
                  onChange={handleChange}
                  style={{ paddingRight: '5px' }}
                  error={Boolean(fieldErrors.ClientName)}
                  helperText={fieldErrors.ClientName}
                />
          }

        {mode == 'edit' && 
                <TextField
                  label="Client Name"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  name="ClientName"
                  value={formData.ClientName}
                  onChange={handleChange}
                  style={{ paddingRight: '5px' }}
                  error={Boolean(fieldErrors.ClientName)}
                  helperText={fieldErrors.ClientName}
                />
          }
                <TextField
                  label="Client Industry"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  style={{ paddingLeft: '5px' }}
                  name="ClientIndustry"
                  value={formData.ClientIndustry}
                  onChange={handleChange}
                  error={Boolean(fieldErrors.ClientIndustry)}
                  helperText={fieldErrors.ClientIndustry}
                />
              </div>
              <TextField
                label="Job Description"
                variant="outlined"
                fullWidth
                multiline
                rows={10}
                margin="normal"
                name="jobDescription"
                value={formData.jobDescription}
                onChange={handleChange}
                error={Boolean(fieldErrors.jobDescription)}
                helperText={fieldErrors.jobDescription}
              />
            </>
          )}
          {mode === 'delete' && (
            <div style={{ padding: '25px 0px 10px', textAlign: 'center' }}>
              <p>Are you sure you want to delete this job?</p>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" size="small" id="button-form">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary" variant="contained" size="small" style={{ fontSize: '18px' }}>
            {mode === 'add' ? 'Save' : 'Update'}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={deleteOpen} onClose={onDeleteClose} maxWidth={'sm'} fullWidth>
        <DialogTitle sx={{ backgroundColor: '#1976d2', color: 'white', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '8px', fontSize: '20px' }}>
          <span>Delete Job </span>
          {/* <IconButton onClick={onDeleteClose} style={{ color: 'white' }}>
            <CloseIcon />
          </IconButton> */}
        </DialogTitle>
        <DialogContent>
          <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center', background: 'rgba(255,255,255,0.5)', left: '50%', top: '50%' }}>
            {isLoading && <Loader />}
          </div>
          <div style={{ padding: '25px 0px 10px', textAlign: 'center' }}>
            <p>Are you sure you want to delete this job? </p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onDeleteClose} variant="outlined" size="small" id="button-form">
            Cancel
          </Button>
          <Button onClick={deleteJob} color="primary" variant="contained" size="small" style={{ fontSize: '18px' }}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <div>
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
          <MuiAlert elevation={6} variant="filled" onClose={handleCloseSnackbar} severity={snackbarSeverity}>
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>
      </div>
    </Box>
  );
};

AddJobModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default AddJobModal;
