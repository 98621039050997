import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import GlobalVariable from '../../path/global';
import axios from 'axios'; // Import Axios library

const baseApiUrl = GlobalVariable.BASE_API_URL;

const DeleteUserModal = ({ open, onClose, onDelete, id }) => {
  const handleDelete = async () => {
    try {
      // Perform API call to delete the user
      const response = await axios.put(`${baseApiUrl}DeActiveUser/${id}`);

      // Check if the deletion was successful
      if (response.status === 200) {
        // Call onDelete callback to handle deletion in parent component
        onDelete(id);
      } else {
        // Handle error condition
        console.error('Failed to delete user:', response.data);
      }
    } catch (error) {
      // Handle API call error
      console.error('Error deleting user:', error);
    }

    // Close the modal regardless of the outcome
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle style={{ backgroundColor: '#4880FF', color: 'white', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '16px' }}>
        Delete User
        {/* <IconButton onClick={onClose} style={{ color: 'white' }}>
          <CloseIcon />
        </IconButton> */}
      </DialogTitle>
      <DialogContent style={{ padding: '16px' }}>
        <p style={{ marginBottom: '16px' }}>Are you sure you want to delete the user?</p>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="contained">
          Cancel
        </Button>
        <Button onClick={handleDelete} color="primary" variant="contained">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DeleteUserModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
};

export default DeleteUserModal;
