import React from 'react';
import Login from '../../Login/login'
// import { Routes, Route} from 'react-router-dom';

const LoginRoute = () => {
 return(
  
<Login/>
 )

};

export default LoginRoute;
