import React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import  { useState, useEffect } from 'react';

function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

const ResBreadcrumbs = ({ title, data }) => {
  const [isHidden, setIsHidden] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      // Update the state based on the screen width
      setIsHidden(window.innerWidth <= 768);
    };

    // Attach event listener for window resize
    window.addEventListener('resize', handleResize);

    // Initial check for screen width
    handleResize();

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <div className={`res-breadcrumbs ${isHidden ? 'hidden' : ''}`}>
    <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} style={{paddingBottom: '10px'}}>
      <Box display={'flex'} alignItems={'center'} justifyContent={'flex-start'}>
        <div className='breadcrumbs-top'>
            <div className=''>
              {title ? <h2 className=' float-start mb-0' style={{color:'#1976d2'}}>{title}</h2> : ''}
            </div>
          </div>
      </Box>
      
      <Box display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>

      <div role="presentation" onClick={handleClick} >
      <Breadcrumbs aria-label="breadcrumb" >
        {data.map((item, index) => (
          <Link
            key={index}
            underline="hover"
            color="inherit"
            href={item.link}
            style={{fontSize: '18px', fontWeight: '600', textDecoration: 'none', color:'#1976d2', cursor:'pointer'}}
          >
            {item.title}
          </Link>
        ))}
        <Typography color="text.primary" style={{fontSize: '18px'}}>{title}</Typography>
      </Breadcrumbs>
    </div>
    </Box>
    </Box>
    </div>
    
  );
};

export default ResBreadcrumbs;