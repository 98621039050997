// prod
const GlobalVariable = Object.freeze({
    BASE_API_URL: 'https://jobanalysisnodejsprod.mitresource.com/v1/',
    BASE_API_GOOGLE_URL: 'https://jobanalysispythonprod.mitresource.com/'
})

// const GlobalVariable = Object.freeze({
//     BASE_API_URL: "http://localhost:3000/v1/",
//     BASE_API_GOOGLE_URL: "https://jxyupzqsvp.ap-south-1.awsapprunner.com/"
// })

// dev
// const GlobalVariable = Object.freeze({
//     BASE_API_URL: 'https://jobanalysisnodejsdev.smartranserp.com/v1/',
//     BASE_API_GOOGLE_URL: 'https://jobanalysisdevpython.smartranserp.com/'
// })

// const GlobalVariable = Object.freeze({
//     BASE_API_URL: 'http://localhost:3000/v1/',
//     BASE_API_GOOGLE_URL: 'http://localhost:3000/v1/'
// })

export default GlobalVariable
