// ** User List Component
import { Fragment, useState, useEffect } from 'react'
import { IconButton, InputAdornment, TextField,Input } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
// import SearchIcon from '@material-ui/icons/Search';
// import ClearIcon from '@material-ui/icons/Clear';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { CardContent, FormControl } from '@mui/material';
import Typography from '@mui/material/Typography';
import ResBreadcrumbs from '../../components/Breadcrumbs'
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useForm } from 'react-hook-form'
import GlobalVariable from '../../path/global'
import axios from 'axios';
import SelectDropDown from '../../components/reusableComponents/selectDropDown';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { Button } from '@mui/material';
import ToastSnackbars from '../../components/toastmessage'
import toast from "react-hot-toast"
import Tooltip from '@material-ui/core/Tooltip';
import MySnackbarContent from '@material-ui/core/SnackbarContent';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Loader from '../../components/Loader/SpLoader';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const baseApiUrl = GlobalVariable.BASE_API_URL


const EditUserList = () => {

  const navigate = useNavigate();

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  // const [editedRow, setEditedRow] = useState(null);
  // const [editedValues, setEditedValues] = useState({});
  const [editRow, setEditRow] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [page, setPage] = useState(0);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [password, setPassword] = useState('')
  const [selectedRole, setSelectedRole] = useState('');
  const [roles, setRoles] = useState([]);
  // const [valueFun, setValueFun] = useState(false);
  // const [selectedDate, setSelectedDate] = useState('');
  // const [addTechnology, setAddTechnology] = useState('');
  // const [addingData, setAddingData] = useState([]);
  const [items, setItems] = useState([]);
  const [open, setOpen] = useState(false);
  const [numValid, setNumValid] = useState(false);
  const [prptName, setPromptName] = useState('');
  const [prptDescription, setPromptDescription] = useState('');
  const [updateId, setUpdateId] = useState('');
  const [filterText, setFilterText] = useState('');
  // const [nextSNO, setNextSNO] = useState(1);
  // const [toastMessage, setToastMessage] = useState(false);
  const [canCheck, setCanCheck] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); 



  const { id } = useParams();

  const [decodedId, setDecodedId] = useState('')


  const {
    register,
    handleSubmit,setValue,
    formState: { errors }
  } = useForm({})

  useEffect(() => {
    const decoded = atob(id)
    setDecodedId(decoded)
  }, [id])

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return; // If the user clicks away from the Snackbar, do nothing
    }
    setOpenSnackbar(false); // Close the Snackbar by updating the state variable
  };
  useEffect( () => {
    fetchData()
  },[decodedId])


  const fetchData = async () => {
    setIsLoading(true)

    try {
      const response = await axios.get(`${baseApiUrl}getRoles`);
      const rolesData = response.data.activeData
      const rolesDat = rolesData.map(item => ({
        value: item.id,
        label: item.roleName
      }))
      setRoles(rolesDat); 
      getSingle(rolesDat)
      setIsLoading(false)

      // Assuming the result is an array of objects with id and name fields
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  
};

const getSingle = async (data) => {
  // if (decodedId) {
    setIsLoading(true);
    axios.get(`${baseApiUrl}getSinngleUsers/`+`${decodedId}`)
      .then(response => {
        if (response) {
          const userData = response.data.activeData[0]; // Assuming user properties are directly under 'user' key in the response
          const role = data.find(role => role.value == userData.roleId)
          const roleNameD = role ? role.value : '';
          setName(userData.name);
          setEmail(userData.email);
          setPassword(userData.password);
          setSelectedRole(roleNameD);
          setIsLoading(false);
          setItems(userData.propmtsData)
          setCanCheck(userData.changePrompt);
          setValue("email",userData.email)

        }
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  // }
}


  // useEffect(() => {
   
  
  //   fetchData();
  // }, [decodedId]);


  const userDetails = JSON.parse(localStorage.getItem('userdetails'));

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = () => {
    if (filterText.trim() === '') {
      // getData();
    } else {
      const escapedFilterText = filterText.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
      const filterRegex = new RegExp(escapedFilterText, 'i');

      const filteredItems = items.filter((item) => {
        const nameMatches = item.languageName && filterRegex.test(item.languageName);
        const daysMatches = item.languageDays && (filterRegex.test(item.languageDays) || item.languageDays.toString().includes(filterText));
        return nameMatches || daysMatches;
      });

      setItems(filteredItems);

    }
  };
  const totalPages = Math.ceil(10 / rowsPerPage);


  const handleSearchClear = () => {
    // getData();
    setFilterText('');
  };

  const handleEdit = (rowId) => {
    const selectedItem = items.find((item) => item.id === rowId);

    setEditRow(rowId);
    setUpdateId(rowId);
    setPromptName(selectedItem.promptName);
    setPromptDescription(selectedItem.promptDescription);
  };
  const handleFilterChange = (event) => {
    setFilterText(event.target.value);
  };
  // const handleDelete = (itemId) => {
  //   const confirmDelete = window.confirm('Are you sure you want to delete?');

  //   if (confirmDelete) {

  //     const config = {
  //       headers: { Authorization: userDetails.token },
  //     };

  //     // axios
  //     //   .put(baseApiUrl + `v1/tegremove/${itemId}`, null, config)
  //     //   .then((response) => {
  //     //     console.log('delete res ====>', response);
  //     //     setToastMessage(true);
  //     //     getData();
  //     //   })
  //     //   .catch((error) => {
  //     //     console.error('There was an error!', error);
  //     //   });
  //   }
  // };
  const handleNameChange = (e) => {
    const { value } = e.target
    setName(value)
  }

  const handleEmailChange = (e) => {
    const { value } = e.target
    setEmail(value)
  }

  const handlePasswordChange = (e) => {
    const { value } = e.target
    setPassword(value)
  }
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleChange = async (event) => {
    setSelectedRole(event.value);
    let n = event.value


    try {
      const promptData = await axios.get(`${baseApiUrl}getRolePrompts/${n}`);


      setItems(promptData.data.activeData)

      // setPromptRows(prompts)
    } catch (error) {
      console.error('Error fetching prompt data:', error);
    }
  };




  const onSubmit = async () => {
    setIsLoading(true);

    setIsSubmitDisabled(true)
    const editedPrompts = items.map((row) => ({
      id:row.id,
      promptName: row.promptName,      
      promptDescription: row.promptDescription,
    }));
    const companyId = userDetails.user.companyId
    const users = {
      id : Number(decodedId),
      name, email, roleId: selectedRole, companyId: companyId,
      prompts: editedPrompts,
      changePrompt: canCheck
    };

    await axios.put(`${baseApiUrl}updateUser/${users.id}`,  users )
      .then(response => {

        if (response.status === 200) {
          setSnackbarMessage("User Data Successfully Updated");
          setSnackbarSeverity('success');
          navigate('/users');

          setOpenSnackbar(true);
          setIsLoading(false);

        }
      }).catch(error => {
        if (error.response && error.response.data.status_code === 401) {
          setSnackbarMessage("User unauthorized");
          setSnackbarSeverity('danger');
          setOpenSnackbar(true);   
          setIsLoading(true);

        } else if (error.response && error.response.data.status_code === 422) {
          setSnackbarMessage("Data Object not correc");
          setSnackbarSeverity('danger');
          setOpenSnackbar(true); 
          setIsLoading(true);
       
        } else {
          // Handle generic error
        }
      }).finally(() => {
        setIsSubmitDisabled(false); // Enable the submit button after login attempt
      });


  }

  const UpdateSave = (id) => {
    setNumValid(false);
    setOpen(false);

    const reg = new RegExp('^[0-9]+$');
    const res = /^[a-zA-Z]+$/;

    if (prptName === '') {
      // Handle the case when prptName is empty
    } else if (prptDescription === '') {
      // Handle the case when prptDescription is empty
    } else if (reg.test(prptName) === true) {
      // setOpen(true);
    } else if (res.test(prptDescription) === true) {
      // setOpen(true);
      // setNumValid(true);
    } else {
      const updatedItems = items.map((item) =>
        item.id === id
          ? {
            ...item,
            promptName: prptName,
            promptDescription: prptDescription,
          }
          : item
      );

      setItems(updatedItems);


      setPromptDescription('');
      setPromptName('');
      setEditRow('');
      setUpdateId('');
    }
  };


  const UpdateCancel = () => {
    setEditRow('');
    setPromptDescription('');
    setPromptName('');
  };

  const handleCheck = (event) => {

    setCanCheck(event.target.checked ? 1 : 0);
  }
  const filteredData = []
  // items.filter((item) => {
  //   const nameMatches = item.languageName.toLowerCase().includes(filterText.toLowerCase());
  //   const daysMatches = item.languageDays.toLowerCase().includes(filterText.toLowerCase());
  //   return nameMatches || daysMatches;
  // });
  return (
    <Fragment>
      <ResBreadcrumbs title='Users ' data={[{ title: 'Dashboard' }]} />

      <div className='app-user-list' style={{ position: 'relative' }}>
      <div style={{position: 'absolute', top: '50%', left: '50%', background: 'rgba(255,255,255, 0.5)', zIndex: '1025'}}>
       
        </div>
        <Card>
          <Box sx={{ padding: '10px 15px' }}>
            <Typography fontSize={20} fontWeight={700}>Edit User</Typography>
          </Box>


          <CardContent>
            <Box component={'form'} onSubmit={handleSubmit(onSubmit)} noValidate sx={{ marginBottom: '50px' }}>
              <Grid container spacing={2} alignItems={'center'} sx={{ padding: '0px 0px 16px ' }}>
                <Grid item xs={12} sm={3} md={3}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    sm={12}
                    id="name"
                    label="Name"
                    name="name"
                    {...register('name', {
                      required: 'Name is required'
                    })}
                    error={Boolean(errors.name)}
                    helperText={errors.name?.message}
                    value={name}
                    onChange={(e) => {
                      handleNameChange(e)
                    }}
                    autoComplete="Off"
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    disabled
                    sm={12}
                    id="email"
                    label="Email"
                    name="email"
                    {...register('email', {
                      required: 'Email is required'
                    })}
                    error={Boolean(errors.email)}
                    helperText={errors.email?.message}
                    value={email}
                    onChange={(e) => {
                      handleEmailChange(e)
                    }}
                    autoComplete="Off"
                    className='demo-simple-select-label'

                  />
                </Grid>
                {/* <Grid item xs={12} sm={3} md={3}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    type={showPassword ? "text" : "password"}
                    name="password"
                    value={password}
                    label="Password"
                    {...register('password', {
                      required: 'Password is required',
                      minLength: {
                        value: 6,
                        message: 'Password must be at least 6 characters',
                      },
                    })}
                    error={Boolean(errors.password)}
                    helperText={errors.password?.message}
                    onChange={(e) => {
                      handlePasswordChange(e)
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    id="password"
                    autoComplete="current-password"
                  />
                </Grid> */}
                <Grid item xs={12} sm={3} md={3}>
                  <FormControl fullWidth sx={{ top: '3px' }}>
                    <SelectDropDown valueData={roles} value={selectedRole} onJobChange={handleChange} placeholder="Select Role" />
                  </FormControl>
                </Grid>

              </Grid>


              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <div style={{ position: 'relative', width: '100%', marginBottom: '50px' }}>
                    <Table >
                      <div>

                        <div>
                          {/* <FormControl>
                            <Input
                              id="search_filter"
                              type="text"
                              placeholder="Search"
                              value={filterText}
                              onChange={(e) => this.setState({ filterText: e.target.value })
                              }
                              style={{ borderRadius: '50px' }}
                              endAdornment={(
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="Search filter"
                                    onClick={handleSearch}
                                    style={{ paddingTop: '10px', marginRight: '10px' }}
                                  >
                                    <SearchIcon />
                                  </IconButton>
                                  {filterText && ( // Render the clear icon only when filterText has a value
                                    <IconButton
                                      aria-label="Clear search"
                                      onClick={handleSearchClear}
                                      style={{ paddingTop: '10px', marginRight: '10px' }}
                                    >
                                      <ClearIcon />
                                    </IconButton>
                                  )}
                                </InputAdornment>
                              )}
                            />
                          </FormControl> */}
                        </div>
                      </div>
                      <Table className='adduser_table'>
                        <TableHead>
                          <TableRow className='internshiptable' >
                            <TableCell sx={{ width: '15%' }}>
                              Prompt Name
                            </TableCell>
                            <TableCell >Prompt Description</TableCell>
                            <TableCell sx={{ width: '8%' }}>
                              Action
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {(filteredData.length === 0 ? items : filteredData)
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .filter(
                              (row) =>
                                !filterText.length ||
                                row.languageName
                                  .toString()
                                  .toLowerCase()
                                  .includes(filterText.toString().toLowerCase())
                            )
                            .map((item, index) => (
                              <TableRow key={item.id}>
                                <TableCell style={{ padding: '0px', paddingLeft: 15 }}>
                                  {editRow === item.id ? (
                                    <TextField
                                      error={prptName === ''}
                                      id="outlined-error"
                                      helperText={prptName === '' ? 'Please enter name.' : ''}
                                      defaultValue={item.promptName}
                                      value={prptName}
                                      onChange={(e) => setPromptName(e.target.value)}
                                      className='input'
                                    />
                                  ) : (
                                    item.promptName
                                  )}
                                </TableCell>
                                <TableCell style={{ padding: '0px', paddingLeft: 10 }}>
                                  {editRow === item.id ? (
                                    <TextField
                                      error={prptDescription === ''}
                                      id="outlined-error"
                                      helperText={prptDescription === '' ? 'Please enter days.' : ''}
                                      defaultValue={item.promptDescription}
                                      value={prptDescription}
                                      onChange={(e) => setPromptDescription(e.target.value)}
                                      className='input input-jobDes'
                                    />
                                  ) : (
                                    item.promptDescription
                                  )}
                                </TableCell>
                                <TableCell style={{ padding: '0px' }}>
                                  {editRow === item.id ? (
                                    <>
                                      <Tooltip title="Save">
                                        <IconButton onClick={() => UpdateSave(item.id)} className='button-a'>
                                          <CheckCircleIcon />
                                        </IconButton>
                                      </Tooltip>
                                      <IconButton onClick={UpdateCancel} className='button-a'>
                                        <CloseIcon />
                                      </IconButton>
                                    </>
                                  ) : (
                                    <>
                                      <IconButton onClick={() => handleEdit(item.id)} className='button-a'>
                                        <EditIcon />
                                      </IconButton>
                                      {/* <IconButton onClick={() => handleDelete(item.id)} className='button-a'>
                                        <DeleteIcon />
                                      </IconButton> */}
                                    </>
                                  )}
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                      {items.length === 0 ? (
                        <Table>
                          <p
                            style={{
                              alignItems: 'center',
                              width: '100%',
                              justifyContent: 'center',
                              display: 'flex',
                              padding: '10px 0px'
                            }}
                          >
                       {!isLoading && items.length === 0 ? (
                                    <Typography>No data available</Typography>
                                  ) : (
                                    isLoading && <Loader/>
                                  )}
                          </p>
                        </Table>
                      ) : null}
                    </Table>
                    {/* <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={!filterText.length ? items.length : totalPages}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    /> */}
                    {/* {toastMessage && (
                                          <Snackbar
                                            anchorOrigin={{
                                              vertical: 'top',
                                              horizontal: 'right',
                                            }}
                                            open={toastMessage}
                                            autoHideDuration={6000}
                                          // onClose={handletoastCloseMessage}
                                          >
                                            <MySnackbarContent
                                              // onClose={handletoastCloseMessage}
                                              variant="elevation"
                                              message={this.state.notificationMessage}
                                            />
                                          </Snackbar>
                                        )} */}
                  </div>
                </Grid>
              </Grid>
              <Grid container justifyContent={'flex-end'} alignItems={'center'}>

                <div>
                  <FormGroup>

                  <FormControlLabel
  control={<Checkbox checked={canCheck} onChange={handleCheck} />}
  label="Can Change Prompts"
/>

                  </FormGroup>
                </div>
                <Button color="primary" type="submit" disabled={isSubmitDisabled} className='bg-primary' sx={{ marginTop: '10px' }}>
                  Save User
                </Button>
              </Grid>

            </Box>
          </CardContent>

        </Card>

      </div>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <MuiAlert elevation={6} variant="filled" onClose={handleCloseSnackbar} severity={snackbarSeverity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </Fragment>
  )
}

export default EditUserList
