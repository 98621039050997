// ** User List Component
import { Fragment, useState, useEffect } from "react";
import { IconButton, InputAdornment, TextField, Input } from "@mui/material";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useNavigate } from 'react-router-dom'

import Card from "@mui/material/Card";
import { CardContent, FormControl } from "@mui/material";
import Typography from "@mui/material/Typography";
import ResBreadcrumbs from "../../components/Breadcrumbs";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import GlobalVariable from "../../path/global";
import axios from "axios";
import SelectDropDown from "../../components/reusableComponents/selectDropDown";
import Table from "@mui/material/Table";
import SnackbarComponent from "../../components/toastmessage";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
// import TableSortLabel from '@mui/material/TableSortLabel';
import ClearIcon from "@material-ui/icons/Clear";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import SearchIcon from "@material-ui/icons/Search";
import { Button } from "@mui/material";
import ToastSnackbars from "../../components/toastmessage";
import Snackbar from "@material-ui/core/Snackbar";
import toast from "react-hot-toast";
import Tooltip from "@material-ui/core/Tooltip";
import MySnackbarContent from "@material-ui/core/SnackbarContent";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CircularLoader from "../../components/Loader/circularloader";

const baseApiUrl = GlobalVariable.BASE_API_URL;

const AddUserList = () => {

  const navigate = useNavigate();

  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [editedRow, setEditedRow] = useState(null);
  const [editedValues, setEditedValues] = useState({});
  const [editRow, setEditRow] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [page, setPage] = useState(0);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [roles, setRoles] = useState([]);
  // const [valueFun, setValueFun] = useState(false);
  // const [selectedDate, setSelectedDate] = useState('');
  // const [addTechnology, setAddTechnology] = useState('');
  // const [addingData, setAddingData] = useState([]);
  const [items, setItems] = useState([]);
  const [open, setOpen] = useState(false);
  const [numValid, setNumValid] = useState(false);
  const [prptName, setPromptName] = useState("");
  const [prptDescription, setPromptDescription] = useState("");
  const [updateId, setUpdateId] = useState("");
  const [filterText, setFilterText] = useState("");
  const [nextSNO, setNextSNO] = useState(1);
  const [toastMessage, setToastMessage] = useState(false);
  const [canCheck, setCanCheck] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [isLoading, setIsLoading] = useState(false);

  // console.log(promptRows,"pr")

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors
  } = useForm({});

  useEffect(() => {
    // Fetch data from your API endpoint
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseApiUrl}getRoles`);
        const rolesData = response.data.activeData;
        const rolesDat = rolesData.map((item) => ({
          value: item.id,
          label: item.roleName,
        }));
        setRoles(rolesDat); // Assuming the result is an array of objects with id and name fields
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    setEmail("");
    setUserName("");
    setPassword("");
    fetchData();
  }, []);

  const userDetails = JSON.parse(localStorage.getItem("userdetails"));

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = () => {
    if (filterText.trim() === "") {
      // getData();
    } else {
      const escapedFilterText = filterText.replace(
        /[-\/\\^$*+?.()|[\]{}]/g,
        "\\$&"
      );
      const filterRegex = new RegExp(escapedFilterText, "i");

      const filteredItems = items.filter((item) => {
        const nameMatches =
          item.languageName && filterRegex.test(item.languageName);
        const daysMatches =
          item.languageDays &&
          (filterRegex.test(item.languageDays) ||
            item.languageDays.toString().includes(filterText));
        return nameMatches || daysMatches;
      });

      setItems(filteredItems);
    }
  };
  const totalPages = Math.ceil(10 / rowsPerPage);

  const handleSearchClear = () => {
    // getData();
    setFilterText("");
  };

  const handleEdit = (rowId) => {
    const selectedItem = items.find((item) => item.id === rowId);

    setEditRow(rowId);
    setUpdateId(rowId);
    setPromptName(selectedItem.promptName);
    setPromptDescription(selectedItem.promptDescription);
  };
  const handleFilterChange = (event) => {
    setFilterText(event.target.value);
  };
  // const handleDelete = (itemId) => {
  //   const confirmDelete = window.confirm('Are you sure you want to delete?');

  //   if (confirmDelete) {

  //     const config = {
  //       headers: { Authorization: userDetails.token },
  //     };

  //     axios
  //       .put(baseApiUrl + `v1/tegremove/${itemId}`, null, config)
  //       .then((response) => {
  //         console.log('delete res ====>', response);
  //         setToastMessage(true);
  //         getData();
  //       })
  //       .catch((error) => {
  //         console.error('There was an error!', error);
  //       });
  //   }
  // };
  const handleNameChange = (e) => {
    const { value } = e.target;
    setUserName(value);
    setValue('userName', e.target.value);
    clearErrors('userName');
    
  };

  const handleEmailChange = (e) => {
    const { value } = e.target;
    setEmail(value);
    setValue('email', e.target.value);
    clearErrors('email');
  };

  const handlePasswordChange = (e) => {
    const { value } = e.target;
    setPassword(value);
    setValue('password', e.target.value);
    clearErrors('password');
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = async (event) => {
    setSelectedRole(event.value);
    setIsLoading(true);
    let n = event.value;

    try {
      const promptData = await axios.get(`${baseApiUrl}getRolePrompts/${n}`);


      setItems(promptData.data.activeData);
      setIsLoading(false);
      // setPromptRows(prompts)
    } catch (error) {
      console.error("Error fetching prompt data:", error);
    }
  };

  const onSubmit = async () => {
    setIsSubmitDisabled(true);
    setIsLoading(true);
    const editedPrompts = items.map((row) => ({
      promptName: row.promptName,
      promptDescription: row.promptDescription,
    }));
    const companyId = userDetails.user.companyId;

    const userPayload = {
      name: userName,
      email: email,
      password: password,
      roleId: selectedRole,
      companyId: companyId,
      prompts: editedPrompts,
      changePrompt: canCheck,
    };

    await axios
      .post(`${baseApiUrl}createuser`, userPayload)
      .then((response) => {
        if (response.data.status === false) {
          setSnackbarMessage(response.data.message);
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
          setIsLoading(false);
        } else if (response.status === 200) {
          setSnackbarMessage("User Added Successfully");
          setSnackbarSeverity("success");
          setSnackbarOpen(true);
          navigate('/users');
          setIsLoading(false);
        }
      })
      .catch((error) => {
        if (error.response && error.response.data.status_code === 401) {
          setSnackbarMessage("User Authentication failed");
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
          setIsLoading(true);
        } else if (error.response && error.response.data.status_code === 422) {
          setSnackbarMessage("Please check the your data object");
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
          setIsLoading(true);
        } else {
          // Handle generic error
        }
      })
      .finally(() => {
        setIsSubmitDisabled(false); // Enable the submit button after login attempt
      });
    setEmail("");
    setUserName("");
    setPassword("");
    setSelectedRole("");
  };

  const UpdateSave = (id) => {
    setNumValid(false);
    setOpen(false);

    const reg = new RegExp("^[0-9]+$");
    const res = /^[a-zA-Z]+$/;

    if (prptName === "") {
      // Handle the case when prptName is empty
    } else if (prptDescription === "") {
      // Handle the case when prptDescription is empty
    } else if (reg.test(prptName) === true) {
      setOpen(true);
    } else if (res.test(prptDescription) === true) {
      setOpen(true);
      setNumValid(true);
    } else {
      const updatedItems = items.map((item) =>
        item.id === id
          ? {
              ...item,
              promptName: prptName,
              promptDescription: prptDescription,
            }
          : item
      );

      setItems(updatedItems);

      setPromptDescription("");
      setPromptName("");
      setEditRow("");
      setUpdateId("");
    }
  };

  const UpdateCancel = () => {
    setEditRow("");
    setPromptDescription("");
    setPromptName("");
  };

  const handleCheck = (event) => {

    setCanCheck(event.target.checked ? 1 : 0);
  };
  const filteredData = [];
  // items.filter((item) => {
  //   const nameMatches = item.languageName.toLowerCase().includes(filterText.toLowerCase());
  //   const daysMatches = item.languageDays.toLowerCase().includes(filterText.toLowerCase());
  //   return nameMatches || daysMatches;
  // });
  return (
    <Fragment>
      <ResBreadcrumbs title="Users " data={[{ title: "Dashboard" }]} />

      <div className="app-user-list" style={{ position: "relative" }}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            background: "rgba(255,255,255, 0.5)",
            zIndex: "1025",
          }}
        >
          {isLoading && <CircularLoader />}
        </div>

        <Card>
          <Box sx={{ padding: "10px 15px" }}>
            <Typography fontSize={20} fontWeight={700}>
              Add User
            </Typography>
          </Box>
          <CardContent>
            <Box
              component={"form"}
              onSubmit={handleSubmit(onSubmit)}
              noValidate
              sx={{ marginBottom: "50px" }}
            >
              <Grid
                container
                spacing={2}
                alignItems={"center"}
                sx={{ padding: "0px 0px 16px " }}
              >
                <Grid item xs={12} sm={3} md={3}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    sm={12}
                    id="userName"
                    label="User Name"
                    name="userName"
                    {...register("userName", {
                      required: "User Name is required",
                    })}
                    
                    error={Boolean(errors.userName)}
                    helperText={errors.userName?.message}
                    value={userName}
                    onChange={(e) => {
                      
                      handleNameChange(e);
                    }}
                    autoComplete="Off"
                    autoFocus
                    sx={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    sm={12}
                    id="email"
                    label="Email"
                    name="email"
                    {...register("email", {
                      required: "Email is required",
                    })}
                    error={Boolean(errors.email)}
                    helperText={errors.email?.message}
                    value={email}
                    onChange={(e) => {
                      handleEmailChange(e);
                    }}
                    autoComplete="Off"
                    sx={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    type={showPassword ? "text" : "password"}
                    name="password"
                    value={password}
                    label="Password"
                    {...register("password", {
                      required: "Password is required",
                      minLength: {
                        value: 6,
                        message: "Password must be at least 6 characters",
                      },
                    })}
                    error={Boolean(errors.password)}
                    helperText={errors.password?.message}
                    onChange={(e) => {
                      handlePasswordChange(e);
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    id="password"
                    autoComplete="current-password"
                    sx={{ width: "100%" }}
                    className="password-input"
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <FormControl fullWidth sx={{ top: "3px" }}>
                    <SelectDropDown
                      valueData={roles}
                      value={selectedRole}
                      onJobChange={handleChange}
                      placeholder="Select Role"
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <div
                    style={{
                      position: "relative",
                      width: "100%",
                      marginBottom: "50px",
                    }}
                  >
                    <Table>
                      <div>
                        <div></div>
                      </div>
                      <Table className="adduser_table">
                        <TableHead>
                          <TableRow className="internshiptable">
                            <TableCell>Prompt Name</TableCell>
                            <TableCell style={{ width: "70%" }}>
                              Prompt Description
                            </TableCell>
                            <TableCell>Action</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {(filteredData.length === 0 ? items : filteredData)
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .filter(
                              (row) =>
                                !filterText.length ||
                                row.languageName
                                  .toString()
                                  .toLowerCase()
                                  .includes(filterText.toString().toLowerCase())
                            )
                            .map((item, index) => (
                              <TableRow key={item.id}>
                                <TableCell
                                  style={{ padding: "0px", paddingLeft: 15 }}
                                >
                                  {editRow === item.id ? (
                                    <TextField
                                      error={prptName === ""}
                                      id="outlined-error"
                                      helperText={
                                        prptName === ""
                                          ? "Please enter name."
                                          : ""
                                      }
                                      defaultValue={item.promptName}
                                      value={prptName}
                                      onChange={(e) =>
                                        setPromptName(e.target.value)
                                      }
                                      className="input"
                                    />
                                  ) : (
                                    item.promptName
                                  )}
                                </TableCell>
                                <TableCell
                                  style={{ padding: "0px", paddingLeft: 10 }}
                                >
                                  {editRow === item.id ? (
                                    <TextField
                                      error={prptDescription === ""}
                                      id="outlined-error"
                                      helperText={
                                        prptDescription === ""
                                          ? "Please enter days."
                                          : ""
                                      }
                                      defaultValue={item.promptDescription}
                                      value={prptDescription}
                                      onChange={(e) =>
                                        setPromptDescription(e.target.value)
                                      }
                                      className="input input-jobDes"
                                    />
                                  ) : (
                                    item.promptDescription
                                  )}
                                </TableCell>
                                <TableCell style={{ padding: "0px" }}>
                                  {editRow === item.id ? (
                                    <>
                                      <Tooltip title="Save">
                                        <IconButton
                                          onClick={() => UpdateSave(item.id)}
                                        >
                                          <CheckCircleIcon />
                                        </IconButton>
                                      </Tooltip>
                                      <IconButton onClick={UpdateCancel}>
                                        <CloseIcon />
                                      </IconButton>
                                    </>
                                  ) : (
                                    <>
                                      <IconButton
                                        onClick={() => handleEdit(item.id)}
                                      >
                                        <EditIcon />
                                      </IconButton>
                                      {/* <IconButton onClick={() => handleDelete(item.id)}>
                                        <DeleteIcon  style={{display:'none'}}/>
                                      </IconButton> */}
                                    </>
                                  )}
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                      {items.length === 0 ? (
                        <Table>
                          <p
                            style={{
                              textAlign: "center",
                              width: "100%",
                              alignSelf: "center",
                            }}
                          >
                            No data found
                          </p>
                        </Table>
                      ) : null}
                    </Table>

                    {toastMessage && (
                      <Snackbar
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        open={toastMessage}
                        autoHideDuration={6000}
                        // onClose={handletoastCloseMessage}
                      >
                        <MySnackbarContent
                          // onClose={handletoastCloseMessage}
                          variant="elevation"
                          message={this.state.notificationMessage}
                        />
                      </Snackbar>
                    )}
                  </div>
                </Grid>
              </Grid>
              <Grid container justifyContent={"flex-end"} alignItems={"center"}>
                <div>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox />}
                      onChange={handleCheck}
                      label="Can Change Prompts"
                    />
                  </FormGroup>
                </div>
                <Button
                  color="primary"
                  type="submit"
                  disabled={isSubmitDisabled}
                  className="bg-primary"
                  sx={{ marginTop: "10px" }}
                >
                  Save User
                </Button>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </div>

      <SnackbarComponent
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </Fragment>
  );
};

export default AddUserList;
