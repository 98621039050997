// import { colors } from '@mui/material';
import React from 'react';
import Box from '@mui/material/Box';
// import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import CardContent from '@mui/material/CardContent';

const NotFound = () => {
  return (
    <div className='Notfound-container'>
     
          <CardContent>
            <Box display={'flex'} width={'100%'} className="p-5px">
            <Typography color='#202224' paddingRight={1} sx={{ fontSize: 45 }} style={{ fontWeight: '900' }} >
                  404-
                </Typography>
               <Typography color='#FFFFFF' paddingRight={1} sx={{ fontSize: 45 }} style={{ fontWeight: '700' }} >
                  Page
                </Typography>
                <Typography color='#FFFFFF' sx={{ fontSize: 45 }} style={{ fontWeight: '700' }}>
                  Not Found
                </Typography>
              </Box>
           </CardContent>

        
    </div>
  );
};

export default NotFound;
