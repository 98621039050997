import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const ThemeLoadingOverlay = () => {
  return (
    <div>
     {/* <CircularProgress /> */}
     <div className='loader-25'></div>
    </div>
  );
};

export default ThemeLoadingOverlay;