import {React ,startTransition} from 'react';
import { PeopleAltOutlined, ContentPasteSearchOutlined, NoteAddOutlined, InfoOutlined, AnalyticsOutlined } from '@mui/icons-material';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import { Link, useLocation, useNavigate} from 'react-router-dom';
const getFilteredMenu = (roleId) => {
  const SideMenuList = [
    {
      id: 1,
      name: 'users',
      displayName: 'Users',
      link: '/users',
      icon: <PeopleAltOutlined />,
    },
    {
      id: 2,
      name: 'profile-matching',
      displayName: 'Profile Matching',
      link: '/profile-matching',
      icon: <ContentPasteSearchOutlined />,
    },
    {
      id: 3,
      name: 'jobde-description',
      displayName: 'Job Description',
      link: '/job-description',
      icon: <NoteAddOutlined />,
    },
    {
      id: 4,
      name: 'prompts',
      displayName: "Prompts",
      link: '/prompts',
      icon: <InfoOutlined />,
    },
    {
      id: 5,
      name: 'roleprompts',
      displayName: "Role Prompts",
      link: '/role-prompts',
      icon: <InfoOutlined />,
    },
    {
      id: 6,
      name: 'previous-analysis-report',
      displayName: "Analysis Report",
      link: '/analysis-report',
      icon: <AnalyticsOutlined />,
    },
  ];

  const orderedMenuNames = [
    'profile-matching',
    'previous-analysis-report',
    'prompts',
    'jobde-description',
    'users',
    'roleprompts',
  ];

  const filteredMenu = orderedMenuNames.map(menuName => {
    return SideMenuList.find(item => item.name === menuName);
  });

  return filteredMenu.filter(item => {
    if ((roleId === 1) || (roleId === 4)) {
      return true; // Display all menus for role 1
    } else if ((roleId === 2)  && (item.id === 2 || item.id === 6 || item.id === 4 || item.id === 3)) {
      return true; // Display only specific menus for role 2
    } else if ((roleId === 3)  && (item.id === 2 || item.id === 6 || item.id === 4 || item.id === 3)) {
      return true; // Display only specific menus for role 3
    } else if ((roleId === 5)  && (item.id === 2 || item.id === 6 || item.id === 4 || item.id === 3)) {
      return true; // Display only specific menus for role 2
    } else if ((roleId === 6)  && (item.id === 2 || item.id === 6 || item.id === 4 || item.id === 3)) {
      return true; // Display only specific menus for role 3
    }
    return false; // Don't display other menus
  });
};

// Example usage:
const roleId = 2; // Replace with the actual roleId you have
const filteredMenu = getFilteredMenu(roleId);
// Now 'filteredMenu' contains the menu items based on the roleId and the specified order in the loop


const SideMenu = ({ open, handleProfileMatchingClick }) => {
const location = useLocation();
  const navigate = useNavigate();
  const userRole = localStorage.getItem('userdetails');
  const ParseData = JSON.parse(userRole);

  if (!ParseData || !ParseData.user) {
    // If user details are not available, return null or handle accordingly
    return null;
  }
   

  // const roleId = ParseData && ParseData.user ? ParseData.user.roleId :null;
  const roleId = ParseData.user?.roleId;
  const filteredMenu = getFilteredMenu(roleId, open);
 

  

  const handleMenuItemClick = (link) => {
    startTransition(() => {
      const accessToken = localStorage.getItem('access_token');
      if (!accessToken) {
        console.log("User is not authenticated. Redirect to login page or handle accordingly.");
        return;
      }

          // Check the link and user role before navigating
      if (link === '/job-description' && roleId !== 3) {
              // If the link is 'job-description' and the role is not 3, do not navigate
        console.log("You don't have permission to access Job Description");
          } else {
        // Navigate only if the condition is met
        navigate(link);
      }
  if (handleProfileMatchingClick) {
    handleProfileMatchingClick(); // Call the handleProfileMatchingClick function
  }
    });
  };
  
  return (
    <>
      {filteredMenu.map((menu) => (
        <ListItem disablePadding key={menu.id}>
          <ListItemButton
            component={Link}
            to={menu.link}
            active={open && location.pathname === menu.link ? true : false}
            onClick={() => {
              handleMenuItemClick(menu.link);
              if (handleProfileMatchingClick) {
                handleProfileMatchingClick(); // Call the handleProfileMatchingClick function
              }
            }}
            sx={{
              backgroundColor: open && location.pathname === menu.link ? '#1976d2' : 'transparent',
              color: open && location.pathname === menu.link ? '#fff' : 'inherit', // Set text color to #fff when active
              '&:hover': {
                backgroundColor: '#1976d2',
                color: '#fff' // You can customize the hover color
              }
            }}
          >
            {open ? (
              <ListItemIcon sx={{ color: open && location.pathname === menu.link ? '#fff' : '#1976d2' }}>
                {menu.icon}
              </ListItemIcon>
            ) : (
              <Tooltip title={menu.displayName} placement="right" arrow>
                <ListItemIcon >
                  {menu.icon}
                </ListItemIcon>
              </Tooltip>
            )}
            <ListItemText primary={menu.displayName} />
          </ListItemButton>
        </ListItem>
      ))}
    </>
  );
};

export default SideMenu;