import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Card from '@mui/material/Card';
import { useForm, Controller  } from 'react-hook-form'
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Pagination from '@mui/material/Pagination';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import MenuItem from '@mui/material/MenuItem';
import SelectDropDown from '../../components/reusableComponents/selectDropDown';
import Box from '@mui/material/Box';
import { FormControl } from '@mui/material';
import axios from 'axios';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import GlobalVariable from '../../path/global';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import Loader from '../../components/Loader/SpLoader';
import CircularLoader from '../../components/Loader/circularloader';
// import { Table } from 'antd';


const Prompts = ({openSnackbar,}) => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesToShow, setEntriesToShow] = useState(5); // Default value is 5
  const [roles, setRoles] = useState([]);
  const [items, setItems] = useState([]);
  const [selectedRole, setSelectedRole] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [isRoleSelected, setIsRoleSelected] = useState(false);
  const [dialogType, setDialogType] = useState('add');
  const baseApiUrl = GlobalVariable.BASE_API_URL;
  const locaUserId = JSON.parse(localStorage.getItem('userdetails'));
  const currentRoleId = locaUserId.user.roleId
  const [searchData, setSearchData] = useState([]);
  const [draggedRowIndex, setDraggedRowIndex] = useState(null);


  const [editedItem, setEditedItem] = useState({
    id: '',
    promptName: '',
    promptDescription: '',
    roleId: '',
  });

  const validationSchema = Yup.object().shape({
    promptName: Yup.string().required('Prompt Name is required'),
    promptDescription: Yup.string().required('Prompt Description is required'),
  });

  
  const { handleSubmit, formState: { errors }, setValue, control, clearErrors } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const handleEditClick = (row) => {
    setDialogType('edit');
    setEditedItem({
      id: row.id,
      promptName: row.promptName,
      promptDescription: row.promptDescription,
      userName: row.userName,
      userId: row.userId
    });
    setOpen(true);
    setValue('promptName', row.promptName);
    setValue('promptDescription', row.promptDescription);
  };
  const handleClose = () => {
    setValue('promptName', ''); // Clear the promptName field
    setValue('promptDescription', ''); // Clear the promptDescription fieldsetValue('value','')
    Object.keys(errors).forEach((field) => {
      clearErrors(field)
    })
    setOpen(false);
    setDeleteOpen(false)
  };

  const handleRowDragStart = (params) => {
    setDraggedRowIndex(params.rowIndex);
    console.log(params.rowIndex,"dragrow")

  };

  const handleRowDragEnd = (params) => {
    const { destination } = params;
  
    // If the destination is null or the row is dropped in its original position, return
    if (!destination || destination.index === draggedRowIndex) {
      return;
    }
  
    // Reorder the items array based on the new order of rows
    const reorderedItems = Array.from(items);
    const [removed] = reorderedItems.splice(draggedRowIndex, 1);
    reorderedItems.splice(destination.index, 0, removed);
    setItems(reorderedItems);
  };


  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 600);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const localStorageData = JSON.parse(localStorage.getItem('userdetails'));    
    

      try {
        // await validationSchema.validate(errors, { abortEarly: false });
        setIsLoading(true);
        const response = await axios.get(`${baseApiUrl}getRoles`);
        const rolesData = response.data.activeData;
        const rolesDat = rolesData.map((item) => ({
          value: item.id,
          label: item.roleName,
        }));

        let n =  rolesDat.find(x => x.value === localStorageData.user.roleId)
        setSelectedRole(n.value)

        if (currentRoleId === 2) {
          const filteredRoles = rolesDat.filter((role) => role.label !== "Admin");
          setRoles(filteredRoles);
          setIsLoading(false); 
        } else {
          setRoles(rolesDat);
          setIsLoading(false);
        }
       
        getRollData()

      } catch (error) {
        console.error('Error fetching data:', error);
      } finally{
        setIsLoading(false);
      }
    
    };

    
    fetchData();
    const localStorageData = JSON.parse(localStorage.getItem('userdetails'));    

    if(localStorageData){
      const roleId = localStorageData.user.changePrompt;

      if(roleId === 1){
        setIsRoleSelected(true);
      }else{
        setIsRoleSelected(false);
      }
    }
  }, [baseApiUrl, currentRoleId]);

  const getRollData = async() => {
    const localStorageData = JSON.parse(localStorage.getItem('userdetails'));    

    const roleId = localStorageData?.user?.roleId
      

    try {
      setIsLoading(true);
      const promptData = await axios.get(`${baseApiUrl}getRolePrompts/${roleId}`);
      setCurrentPage(1)
      setItems(promptData.data.activeData);
      setSearchData(promptData.data.activeData)

    } catch (error) {
      console.error('Error fetching prompt data:', error);
    } finally {
      setIsLoading(false);
    }

  }

  

  const handleSelectChange = async (event) => {
    setSelectedRole(event.value);
    let n = event.value;

    // const localStorageData = JSON.parse(localStorage.getItem('userdetails'));    

    // const roleId = {
    //   roleId: n,
    //   companyId : localStorageData.user.companyId

    // };

    try {
      setIsLoading(true);
      const promptData = await axios.get(`${baseApiUrl}getRolePrompts/${n}`);



      setItems(promptData.data.activeData);
      setSearchData(promptData.data.activeData)

    } catch (error) {
      console.error('Error fetching prompt data:', error);
    } finally {
      setIsLoading(false);
    }

    
  };

  const columns = [
    { field: 'promptName', headerName: 'Prompt Name', width:150, headerClassName: 'bg-primary text-white text-size',  renderCell: (params) => (
      <Tooltip title={<div style={{ fontSize: '14px'}}> {params.value }</div>}
      arrow >
        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {params.value}
        </div>
      </Tooltip>
    )},
    { field: 'promptDescription', headerName: 'Prompt Description', flex:1, headerClassName: 'bg-primary text-white text-size', renderCell: (params) => (
      <Tooltip title={<div style={{ fontSize: '14px'}}> {params.value }</div>}
      arrow >
        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {params.value}
        </div>
      </Tooltip>
    )},
    locaUserId.user.changePrompt === 1 && {
      field: 'actions',
      headerName: 'Actions',
      width:100,
      renderCell: (params) => (
        <div style={{ display: 'flex' }}>
          <>
            <Tooltip title="Edit" arrow>
              <IconButton
                size="small"
                style={{ marginRight: '8px' }}
                onClick={() => {
                  handleEditClick(params.row);
                }}
                className='button-a'
              >
                <EditIcon color="primary" />
              </IconButton>
            </Tooltip>
            
            {locaUserId.user.roleId === 1 && ( // Only render Delete button if roleId is 1
                <Tooltip title="Delete" arrow>
                  <IconButton
                    size="small"
                    onClick={() => handleDeleteClick(params.row)}
                    className='button-a'
                  >
                    <DeleteIcon color="primary" />
                  </IconButton>
                </Tooltip>
              )}
          </>
        </div>
      ),
      headerClassName: 'bg-primary text-white text-size',
    },
  ].filter(Boolean);

  const getRowId = (row) => row.id;

  const CustomPagination = () => (
    <div style={{ width:'100%', justifyContent: 'space-between', alignItems: 'center', marginTop: '8px' }}>
      <div  style={{ float:'left', margin:'8px 0 0 8px' }}>
      Showing {Math.min((currentPage - 1) * entriesToShow + 1, items.length)} to {Math.min(currentPage * entriesToShow, items.length)} of {items.length} entries
      </div>
      <div style={{ display: 'flex', alignItems: 'center',float:'right', paddingBottom: '10px' }}>
        <Typography style={{ marginRight: '8px' }}>Rows</Typography>
        <TextField
          select
          variant="outlined"
          size="small"
          value={entriesToShow}
          onChange={(e) => setEntriesToShow(e.target.value)}
          style={{ width: '150px' }} // Reduced the width
        >
          {[5, 10, 25, 50, 100].map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
        <Pagination
          color="primary"
          count={Math.ceil(items.length / entriesToShow)}
          page={currentPage}
          onChange={(event, value) => setCurrentPage(value)}
          sx={{width: '100%'}}
        />
      </div>
    </div>
  );

  const handleAddModalOpen = () => {
    setDialogType('add');
    setEditedItem(null)
    setOpen(true);
  };

  const handleDeleteClick = (row) => {
    setDialogType('delete');
    setEditedItem({
      id: row.id
    });
    setDeleteOpen(true);
  };


  const handleDeleteSubmit = async (data, e) => {
      setIsLoading(true);
    const localStorageData = JSON.parse(localStorage.getItem('userdetails'));    
      const roleId = {
        roleId: selectedRole,
        companyId : localStorageData.user.companyId
      }
      const res = await axios.put(`${baseApiUrl}deActiveRolePrompt/${editedItem.id}`)
      if(res){
        openSnackbar('success', "Prompt deleted successfully");
        setCurrentPage(1)

        const promptData = await axios.get(`${baseApiUrl}getRolePrompts/${selectedRole}`);
        setItems(promptData.data.activeData);
        // getRollData()
        handleClose();
        setIsLoading(false)
        setDeleteOpen(false);
      }
  }


  const onSubmit = async (data, e) => {

    const localStorageData = JSON.parse(localStorage.getItem('userdetails'));    
    const roleId = {
      roleId: selectedRole,
      companyId : localStorageData.user.companyId
    }

    try{
      // await validationSchema.validate(errors, { abortEarly: false });
      setIsLoading(true)
      if(dialogType === 'add') {
        const payload = {
          companyId: roleId.companyId,
          roleId: selectedRole,
          promptName: data.promptName,
          promptDescription: data.promptDescription
        }
  
        const res = await axios.post(`${baseApiUrl}createRolePrompt`, payload)
        if(res){
          console.log(res)
          openSnackbar('success', "Prompt added successfully");
          const promptData = await axios.get(`${baseApiUrl}getRolePrompts/${selectedRole}`);
          setItems(promptData.data.activeData);
          // setTimeout(() => {
          //   getRollData()
          // }, 500);
          setValue('')
          handleClose();
          setIsLoading(false)
  
        }
      } else if (dialogType === 'edit') {
        const payload = {
          id: editedItem.id,
          roleId: selectedRole,
          promptName: data.promptName,
          promptDescription: data.promptDescription,
          status: 1
        }
        const res = await axios.put(`${baseApiUrl}updateRolePrompt/${editedItem.id}`, payload)

        if(res){
          openSnackbar('success', "Prompt updated successfully");
          const promptData = await axios.get(`${baseApiUrl}getRolePrompts/${selectedRole}`);
          setItems(promptData.data.activeData);
          // getRollData()

          handleClose();
          setIsLoading(false)
        }

      } else if(dialogType === 'delete'){
        
        const res = await axios.put(`${baseApiUrl}deActiveRolePrompt/${editedItem.id}`)
        if(res){

          openSnackbar('success', "Prompt deleted successfully");
          const promptData = await axios.get(`${baseApiUrl}getRolePrompts/${selectedRole}`);
          setItems(promptData.data.activeData);
          // getRollData()

          handleClose();
          setIsLoading(false)
        }
      }else{

      }
      

    }catch(error){
      console.error("Error:", error);
      let errorMessage = "An error occurred";
      if (error.response) {
        if (error.response.data) {
          if (error.response.data.message) {
            errorMessage = error.response.data.message;
          } else {
            errorMessage = JSON.stringify(error.response.data);
          }
        } else {
          errorMessage = JSON.stringify(error.response);
        }
      }
    
      if (error.response && error.response.data && error.response.data.status === 401) {
        openSnackbar('error', errorMessage);
      } else if (error.response && error.response.data && error.response.data.status_code === 422) {
        // Handle other specific status codes as needed
      } else {
        // Handle generic error
        openSnackbar('error', errorMessage);
      }
    }
  }

 


  return (
    <div className="prompts" style={{position: 'relative'}}>    
      <Card variant="outlined">
        <CardContent>
          <Grid container spacing={2} alignItems="center" justifyContent="space-between" sx={{paddingTop: '3px !important'}}>
                  
              <Grid item xs={12} sm={3} md={3} >
                <FormControl fullWidth sx={{ top: '3px' }}>
                  <SelectDropDown
                    valueData={roles}
                    value={selectedRole}
                    onJobChange={handleSelectChange}
                    placeholder="Select Role"
                  />
                </FormControl>
              </Grid>
          
            
           
            <Grid xs={12} sm={3} md={6} container spacing={2} alignItems="center" justifyContent="flex-end" style={{ marginTop: '12px', paddingRight:'18px' }}>
              <Box display="flex" alignItems="center" className="role-prompts-block">
                <Grid item xs={12}>
                 
                    <TextField
                      label="Search"
                      variant="outlined"
                      value={searchTerm}
                      // onChange={(e) => handleFilter(e.target.value)}
                      style={{ marginRight: '8px' }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchOutlinedIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
             
                </Grid>
                {currentRoleId === 1 ? (
                <Grid item xs={12}>
                  <Tooltip title="Add Prompts" arrow>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      style={{ marginLeft: '8px', width: '100%' }}
                      // className="button-t"
                      disabled={!isRoleSelected}
                      onClick={handleAddModalOpen}
                    >
                      Add Prompt
                    </Button>
                  </Tooltip>
                </Grid>
                ) : null}
              </Box>
            </Grid>
          
            <Grid container spacing={2} alignItems="center" justifyContent="flex-end" className='role-prompts-table-first' style={{ marginBottom: '16px', marginTop: '16px', paddingLeft: '16px', paddingRight: '16px' }}>
              <Grid item xs={12}>
                <div style={{ width: '100%' }}>
                  
                  {/* <DataGrid
                    rows={items.slice((currentPage - 1) * entriesToShow, currentPage * entriesToShow)}
                    
                    columns={columns}
                    pageSize={isSmallScreen ? 3 : 5} // Adjust pageSize based on screen size
                    autoHeight
                    getRowId={getRowId}
                    disableRowSelectionOnClick
                    disableColumnMenu
                    pagination
                    paginationMode="server"
                    onPageChange={(params) => setCurrentPage(params.page)}
                    onRowDragStart={handleRowDragStart}
                    onRowDragEnd={handleRowDragEnd}
                    components={{
                            
                      NoRowsOverlay: () => (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%', background: 'rgba(255, 255, 255, 0.8)' }}>
                        {!isLoading && items.length === 0 ? (
                                    <Typography>No data available</Typography>
                        ) : (
                          isLoading && <Loader/>
                        )}
                      </div>
                      
                      ),
                      Pagination: CustomPagination,
                    }}
                    
                   
                   
                  /> */}

                  <DataGrid
                    rows={items.slice((currentPage - 1) * entriesToShow, currentPage * entriesToShow)}
                    
                    columns={columns}
                    pageSize={isSmallScreen ? 3 : 5} // Adjust pageSize based on screen size
                    autoHeight
                    getRowId={getRowId}
                    disableRowSelectionOnClick
                    disableColumnMenu
                    pagination
                    paginationMode="server"
                    onPageChange={(params) => setCurrentPage(params.page)}
                    onRowDragStart={handleRowDragStart}
                    onRowDragEnd={handleRowDragEnd}
                    components={{
                            
                      NoRowsOverlay: () => (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%', background: 'rgba(255, 255, 255, 0.8)' }}>
                        {!isLoading && items.length === 0 ? (
                                    <Typography>No data available</Typography>
                        ) : (
                          isLoading && <Loader/>
                        )}
                      </div>
                      
                      ),
                      Pagination: CustomPagination,
                    }}
                    
                   
                   
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open} />
      <Dialog key={open} open={open} component={'form'} onSubmit={handleSubmit(onSubmit)} noValidate maxWidth={dialogType === 'delete' ? 'sm' : 'md'} fullWidth>
        <DialogTitle style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: '#1976D2', color: 'white',height:"50px" }}>
          {dialogType === 'delete' ? 'Delete Prompt' : dialogType === 'edit' ? 'Edit Prompt' : 'Add Prompt'}
          {/* <IconButton aria-label="close" onClick={handleClose} style={{ color: 'white' }}>
            <CloseIcon />
          </IconButton> */}
        </DialogTitle>
        <DialogContent >
        <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center', background: 'rgba(255,255,255,0.5)', left: '50%', top: '50%' }}>
            {isLoading && <CircularLoader />}
          </div>
        {dialogType !== 'delete' && (
            <>
              <Controller
                name="promptName"
                control={control}
                defaultValue={editedItem?.promptName || ''}
                render={({ field }) => (
                  <TextField
                    label="Prompt Name"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    autoComplete="Off"
                    value={field.value}
                    onChange={(e) => {
                      setValue('promptName', e.target.value);
                       clearErrors('promptName');
                    }}
                    error={Boolean(errors.promptName)}
                    helperText={errors.promptName?.message}
                    required
                  />
                )}
              />

              <Controller
                name="promptDescription"
                control={control}
                defaultValue={editedItem?.promptDescription || ''}
                render={({ field }) => (
                  <TextField
                    label="Prompt Description"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={4}
                    margin="normal"
                    autoComplete="Off"
                    value={field.value}
                    onChange={(e) =>{
                      setValue('promptDescription', e.target.value);
              clearErrors('promptDescription');
                    } }
                    error={Boolean(errors.promptDescription)}
                    helperText={errors.promptDescription?.message}
                    required
                  />
                )}
              />
            </>
          )}
          {dialogType === 'delete' && (
            <Typography style={{textAlign: 'center', paddingTop: '2rem'}}>
              Do you want to delete this prompts
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="outlined"
            size="small"
            id='button-form'
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            size="small"
            type='submit'
            style={{ fontSize: '18px' }}
          >
                  {dialogType === 'delete' ? 'Delete' : dialogType === 'edit' ? 'Update' : 'Save'}

          </Button>
        </DialogActions>
      </Dialog>

      <Dialog key={deleteOpen} open={deleteOpen} noValidate maxWidth={dialogType === 'delete' ? 'sm' : 'md'} fullWidth>
      <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center', background: 'rgba(255,255,255,0.5)', left: '50%', top: '50%' }}>
            {isLoading && <CircularLoader />}
          </div>
        <DialogTitle style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: '#1976D2', color: 'white',height:"50px" }}>
          Delete Prompt
          {/* <IconButton aria-label="close" onClick={handleClose} style={{ color: 'white' }}>
            <CloseIcon />
          </IconButton> */}
        </DialogTitle>
       
        <DialogContent >
            <Typography style={{textAlign: 'center', paddingTop: '2rem'}}>
              Do you want to delete this prompt?
            </Typography>

        </DialogContent>
        <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center', background: 'rgba(255,255,255,0.5)', left: '50%', top: '50%' }}>
            {isLoading && <CircularLoader />}
          </div>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="outlined"
            size="small"
            id='button-form'
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            size="small"
            type='submit'
            onClick={handleDeleteSubmit} 
            style={{ fontSize: '18px' }}
          >
                 Delete

          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Prompts;