// ** User List Component
import { Fragment, useCallback, useState } from 'react'

import Prompts from '../prompts/prompt'
import ResBreadcrumbs from '../../components/Breadcrumbs'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const PromptList = () => {
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const handleOpenSnackbar = (severity, message) => {
        setSnackbarSeverity(severity);
        setSnackbarMessage(message);
        setOpenSnackbar(true);
    };
    // Callback function to close Snackbar
    const handleCloseSnackbar = useCallback(() => {
        setOpenSnackbar(false);
    }, []);

    return (
        <Fragment>
            <ResBreadcrumbs title='Prompts' data={[{ title: 'Dashboard' }]}/>

            <div className='app-user-list'>
                <Prompts openSnackbar={handleOpenSnackbar} />
            </div>
            <div>
                <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                    <MuiAlert elevation={6} variant="filled" onClose={handleCloseSnackbar} severity={snackbarSeverity}>
                    {snackbarMessage}
                    </MuiAlert>
                </Snackbar>
            </div>
        </Fragment>
    )
}

export default PromptList
