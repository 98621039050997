import React, {useCallback, useEffect} from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const SelectDropDown = ({ valueData, value, onJobChange ,placeholder}) => {
    useEffect(() => {
      console.log('SelectDropDown mounted');
      return () => {
        console.log('SelectDropDown unmounted');
      };
    }, []);
    const memoizedOnChange = useCallback(
      (event) => {
        const selectedValue = event.target.value;
        const selectedOption = valueData.find(item => item.value === selectedValue);
    
        if (onJobChange) {
          onJobChange({
            label: selectedOption.label,
            value: selectedValue,
          });
        }
      },
      [onJobChange, valueData]
    );
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">{placeholder}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          label={placeholder}
          onChange={memoizedOnChange}
        >
          {valueData && valueData.map((item, index) => (
            <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default SelectDropDown;
